export const content = {
    title: 'How It Works',
    mobileTitle: 'Joel wants to buy an apartment in Berlin...',
    subtitle: 'We\'ll help you find, buy and renovate your dream apartment. When you don\'t need to live there we\'ll help you to earn income with short term renting',
    personWantsText: 'Joel wants to',
    whatTheyWantText: 'an apartment in',
    steps: [{id: 1, is_default: true, city: null, ownership: null, steps: [
        {title: 'Discovery', time: 'Within 24 hours', text: 'You\'ll tell us where you are interested in buying and what type of property you are looking for.'},
        {title: 'Research', time: 'Within 2 days',  text: 'We\'ll prepare an overview of what is available that meets your criteria.'},
        {title: 'Search', time: 'Within 2 weeks', text: 'An intensive boots-on-the-ground property search will find your dream place. We will do rigorous inspections and make high quality remote viewings for you (4k video and 360 photos).'},
        {title: 'Closing', time: 'Within 4 weeks', text: 'When you are ready to buy you\'ll pay a deposit to lock in your unit. In most countries this is 5% of the purchase price.\n\nWe handle the closing process and are will deliver to you a fully renovated property.'},
        {title: 'Move in', time: 'Within 6 weeks', text: 'You can live in your property for all or part of the year, or short term rent it out for income.\n\nWhichever you choose, we\'ll be there to handle all the details until you decide to sell and start your next big adventure.'},
    ]}],
    cities:   [
        {id: 1, text: 'Berlin', city: 'Berlin', flag: 'de'}, {id: 2, text: 'Paris', city: 'Paris', flag: 'fr'}, {id: 3, text: 'Warsaw', city: 'Warsaw', flag: 'pl'}
    ],
    ownershipOptions:  [
        {id: 1, text: 'Own', flag: null}, {id: 2, text: 'Part own', flag: null}
    ],
    names: [
        {text: 'Sean', id: 1}, {text: 'Joel', id: 2}
    ]
}