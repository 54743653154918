import { content as introduction } from './introduction.js'
import { content as planner } from './planner'
import { content as showroom } from './showroom'
import { content as butler } from './butler'
import { content as howitworks } from './howitworks'
import { content as features } from './features'
import { content as signup } from './signup'
import { content as footer } from './footer'
import { content as faq } from './faq'
import { content as pricing } from './pricing'
import { content as goldstatuscompanies } from './goldstatuscompanies'
import { content as comparison } from './comparison'

export const content = {
    introduction: introduction,
    planner: planner,
    showroom: showroom,
    butler: butler,
    howitworks: howitworks,
    features: features,
    signup: signup,
    footer: footer,
    faq: faq,
    pricing: pricing,
    goldstatuscompanies: goldstatuscompanies,
    comparison: comparison,
}