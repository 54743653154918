export const content = {
    title: "AIRHOMES are the best place for nomads to live",
    data: [
        // each item in the array is a row
        {is_header: true, items: [
            {value: 'AIRHOME Ownership', is_icon: false},
            {value: 'Traditional Ownership', is_icon: false},
            {value: 'Renting', is_icon: false},
            ]
        },
        {is_header: false, items: [
            {value: 'Inflation protection', is_icon: false},
            {value: 'ThumbsUpIcon', is_icon: true},
            {value: 'CheckIcon', is_icon: true},
            {value: 'XIcon', is_icon: true},
            ]
        },
        {is_header: false, items: [
            {value: 'Live wherever you want', is_icon: false},
            {value: 'ThumbsUpIcon', is_icon: true},
            {value: 'XIcon', is_icon: true},
            {value: 'CheckIcon', is_icon: true},
            ]
        },
        {is_header: false, items: [
            {value: 'Switch homes in 30 days', is_icon: false},
            {value: 'ThumbsUpIcon', is_icon: true},
            {value: 'XIcon', is_icon: true},
            {value: 'CheckIcon', is_icon: true},
            ]
        },
        {is_header: false, items: [
            {value: 'Storage for your things', is_icon: false},
            {value: 'ThumbsUpIcon', is_icon: true},
            {value: 'CheckIcon', is_icon: true},
            {value: 'XIcon', is_icon: true},
            ]
        },
        {is_header: false, items: [
            {value: 'Bookable cleaning', is_icon: false},
            {value: 'ThumbsUpIcon', is_icon: true},
            {value: 'XIcon', is_icon: true},
            {value: 'XIcon', is_icon: true},
            ]
        },
        {is_header: false, items: [
            {value: 'Bookable laundry', is_icon: false},
            {value: 'ThumbsUpIcon', is_icon: true},
            {value: 'XIcon', is_icon: true},
            {value: 'XIcon', is_icon: true},
            ]
        },
        {is_header: false, items: [
            {value: 'Dedicated workspace with monitor', is_icon: false},
            {value: 'ThumbsUpIcon', is_icon: true},
            {value: 'MinusIcon', is_icon: true},
            {value: 'MinusIcon', is_icon: true},
            ]
        },
        {is_header: false, items: [
            {value: 'Gym access', is_icon: false},
            {value: 'ThumbsUpIcon', is_icon: true},
            {value: 'MinusIcon', is_icon: true},
            {value: 'MinusIcon', is_icon: true},
            ]
        },
        {is_header: false, items: [
            {value: 'Award winning architect design', is_icon: false},
            {value: 'ThumbsUpIcon', is_icon: true},
            {value: 'MinusIcon', is_icon: true},
            {value: 'XIcon', is_icon: true},
            ]
        },
        {is_header: false, items: [
            {value: 'Luxury handmade furniture', is_icon: false},
            {value: 'ThumbsUpIcon', is_icon: true},
            {value: 'MinusIcon', is_icon: true},
            {value: 'XIcon', is_icon: true},
            ]
        }
    ],
}