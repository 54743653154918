export const content = {
    title: 'Fair, Transparent Pricing',
    subtitle: 'You\'ll pay a fixed % of the price when you actually buy your AIRHOME. If you don\'t buy, you won\'t pay anything.',
    itemTitle: 'We Act As Your Fudiciary',
    itemText: 'You\'ll get efficient and professional property management billed at cost from us for as long as you own your AIRHOME. When you sell, we will continue to manage the property for the new owner. We keep costs as low as possible because we want to create a big network of airhome owners that can buy and sell properties with each other.',
    whatsIncluded: 'What\'s included',
    includedItems: ['Property search', 'Transaction management', 'Renovation management', 'Property management'],
    pricePreTitle: 'One time fee',
    price: '3.5%',
    belowPrice: 'Of property purchase price',
    buttonCTA: 'Learn More',    
}