export const content = {
    title: 'Claim Gold Status Benefits',
    subtitle: 'Employees and contractors for companies on our Gold Status list automatically qualify for the best financing terms and free trials of premium benefits.',
    search: 'Search gold status list',
    companies: [
        'GitLab', '15Five', 'Buffer', 'Zapier', 'Flexjobs', 'Sketch', 'Automatic', 'Hopin', 'Toggl', 'Kona', 'Dollar Flight Club', 'OnTheGoSystems', 'Doist', 'Bandcamp', 'Skillcrush',
    ],
    cta: 'Can\'t Find Your Company?',
    belowCta: 'Provide us with an HR contact and we\'ll get in touch to check if they qualify',
    hr_contact: 'HR contact email',
    your_name: 'Your name',
    your_email: 'Your email'
}