<template>
    <v-app-bar
        app
        :color="$store.state.dynamicColors.navBarColor"
        style="transition: all 0.5s ease;"
        flat
        height=100
        align="top"
        class="ma-3"
        dark>
        <v-spacer/>
        <sign-up-button/>
    </v-app-bar>
</template>

<script>
import SignUpButton from './SignUpButton.vue'
export default {
  components: { SignUpButton },
  name: 'TopAppBar'
}
</script>