export const content = {
    title: 'Frequently Asked Questions',
    questions: [
        {question: 'Do AIRHOMES qualify for Section 1031 Exchange tax deferral on sale?', answer: 'Yes though you need to buy another foreign property (you cannot 1031 exchange a foreign property for a US property). You can switch into any other AIRHOME to defer tax.'},
        {question: 'How much does an AIRHOME cost?', answer: 'It depends on the city. For more affordable cities like Budapest and Warsaw we can find a great apartment for under $200,000 USD. For more expensive cities like Paris the cost can be up to $600,000. If you want to see the prices and returns available, you can check out our Market Overview (link in the footer)'},
        {question: 'I don\'t see the market I am interested in, can I buy there?', answer: 'Yes, we can help you to buy in most major markets. Please get in touch to find out'},
        {question: 'Can I buy part shares?', answer: 'Shares are just like ownership. Just that rather than owning an apartment alone, you hold it alongside others. You can as little as 1% of an apartment. Shares go all the way up to 100%. So if you want to live in 4 cities, you can buy 25% shares in apartments in each place.'},
        {question: 'How do I know the apartment is good?', answer: 'Our construction professionals will check the apartment you are buying in detail. You will get it fully renovated with a 5 year guarantee. If your not happy with the apartment you bought on your first stay, we will switch you into a different unit or give you a refund.'},
        {question: 'When I travel, I need more than just a place to stay... can you help?', answer: 'Every apartment comes with gym membership for a nearby gym and optional access to a co-working space (at additional cost)'},
        {question: 'I need a good workspace. Do AIRHOMES have this?', answer: 'Every apartment can be setup with up to 2 work spaces and monitors for work. We also have arrangements with co-working spaces nearby each apartment.'},
        {question: 'Where do I keep my stuff when I don\'t stay in the apartment?', answer: 'We offer cloud storage. Leave your stuff in the apartment and we will securely store it. It will be back in the unit next time you stay'},
        {question: 'Is this suitable for an investment?', answer: 'Yes, you can own AIRHOMES as high quality investment properties. We provide a detailed breakdown of the yields available in the Market Overview found in the Navigation Footer'},
        {question: 'How do I sell?', answer: 'You can sell at any time via our platform. We will help to find a buyer and get you the best price'},
        {question: 'Can I get a loan?', answer: 'Yes, we can help many of our clients to secure loans. We are constantly hunting for financing partners that are able to offer better terms.'},
        {question: 'How do I know I can trust you?', answer: 'Our team previously worked for one of the worlds largest real estate investment firms. We bring the same strict operating discipline and procedures when we serve every individual client at AIRHOME. We are absolutely committed to transparency and the highest ethical standards. Our company operates in Singapore, one of the strictest juristictions in the world. We only work with brand name service providers.'},
        {question: 'What is the holding structure?', answer: 'You will hold shares in a company that owns just your property. The company will be incorporated in your home juristiction. This simplifies tax reporting and makes buying and selling units simpler. It also protects you from personal tax liabilities in the countries where you own property.'},
        {question: 'What are the terms and conditions of the free stay?', answer: 'AIRHOME, in its sole discretion, has to decide that you are a bona fide potential client. You may be asked to provide a fully refundable security deposit.'}
    ]
}