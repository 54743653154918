import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
      dark: false,
      themes: {
        light: {
            primary: '#21094E',
            secondary: '#02AAB0 ',
            purpleblack: '#0E0420',
            gold: '#FAA139'
        },
      },
    },
  })
  

export default vuetify