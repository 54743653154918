<template>
    <v-footer :dark="$store.state.dynamicColors.darkLogo" absolute :color="$store.state.dynamicColors.darkLogo ? 'purpleblack' : 'white'" app>
        <v-container fill-width>
            <div v-if="!$store.state.dynamicColors.darkLogo" :style="{'background-color': $store.state.dynamicColors.darkLogo ? 'rgb(255,255,255,0.2)' : 'rgb(0,0,0,0.1)'}" style="position: absolute; left: 0; width: 100vw; height: 1px"/>
            <v-row class="mt-6 mb-6">
                <v-col class="ma-3" align="center" v-for="(group, i) in content.groups" :key="i" cols=6 md=3>
                    <v-row>
                        <v-row class="mt-3 mb-3 fullWidthRow">
                            <h5>{{ group.title }}</h5>
                        </v-row>
                        <v-row class="mt-3 mb-3 fullWidthRow" v-for="(item, i) in group.items" :key="i">
                            <div style="cursor: pointer;" @click="$router.push({name: item.to})">
                                <h5 class="footer-link">{{ item.title }}</h5>
                            </div>
                        </v-row>
                    </v-row>
                </v-col>
            </v-row>
            <div :style="{'background-color': $store.state.dynamicColors.darkLogo ? 'rgb(255,255,255,0.2)' : 'rgb(0,0,0,0.1)'}" style="position: absolute; left: 0; width: 100vw; height: 1px"/>
            <v-row class="mt-6 mb-3" justify="center">
                <h5 class="mt-9">© 2022 Hoza Holdings Pte Ltd. All rights reserved.</h5>
            </v-row>
        </v-container>
    </v-footer>
</template>

<script>
export default {
    name: 'PageFooter',
    props: {
        content: {
            type: Object
        }
    }
}
</script>

<style lang="scss" scoped>
.footer-link {
    opacity: 0.76;
    transition: 0.3s all;
}
.footer-link:hover{
    opacity: 1.0;
    transition: 0.3s all;
}

</style>