export const content = {
    title: "Invest Overseas, Like a Local.",
    subtitle: "We made owning an AIRHOME much better than the other ways you could buy an overseas property. Say goodbye to stressful renovations and constantly overpaying.",
    data: [
        // each item in the array is a row
        {is_header: true, items: [
            {value: 'AIRHOME', is_icon: false},
            {value: 'Traditional Ownership', is_icon: false},
            {value: 'Timeshare (Yuck)', is_icon: false},
            ]
        },
        {is_header: false, items: [
            {value: 'Real ownership (freely stay, buy and sell)', is_icon: false},
            {value: 'ThumbsUpIcon', is_icon: true},
            {value: 'CheckIcon', is_icon: true},
            {value: 'XIcon', is_icon: true},
            ]
        },
        {is_header: false, items: [
            {value: 'Easy to manage remotely', is_icon: false},
            {value: 'ThumbsUpIcon', is_icon: true},
            {value: 'XIcon', is_icon: true},
            {value: 'CheckIcon', is_icon: true},
            ]
        },
        {is_header: false, items: [
            {value: 'Renovations and maintanance done for you', is_icon: false},
            {value: 'ThumbsUpIcon', is_icon: true},
            {value: 'XIcon', is_icon: true},
            {value: 'XIcon', is_icon: true},
            ]
        },
        {is_header: false, items: [
            {value: 'Tax filings, bill payments and admin done for you', is_icon: false},
            {value: 'ThumbsUpIcon', is_icon: true},
            {value: 'XIcon', is_icon: true},
            {value: 'XIcon', is_icon: true},
            ]
        },
        {is_header: false, items: [
            {value: '1031 Tax Deferrment When You Sell (US taxpayers only)', is_icon: false},
            {value: 'ThumbsUpIcon', is_icon: true},
            {value: 'MinusIcon', is_icon: true},
            {value: 'XIcon', is_icon: true},
            ]
        },
        {is_header: false, items: [
            {value: 'Invest via an LLC in your home juristiction (US, Singapore, EU, UK, Aus, NZ)', is_icon: false},
            {value: 'ThumbsUpIcon', is_icon: true},
            {value: 'MinusIcon', is_icon: true},
            {value: 'XIcon', is_icon: true},
            ]
        },
        {is_header: false, items: [
            {value: 'Rent other units with 30-50% discount when you travel', is_icon: false},
            {value: 'ThumbsUpIcon', is_icon: true},
            {value: 'XIcon', is_icon: true},
            {value: 'XIcon', is_icon: true},
            ]
        },
        {is_header: false, items: [
            {value: 'Zero-profit PM to align our interests with yours', is_icon: false},
            {value: 'ThumbsUpIcon', is_icon: true},
            {value: 'XIcon', is_icon: true},
            {value: 'XIcon', is_icon: true},
            ]
        },
    ],
}