export const content = {
    title: "Once You Own\n<mark class='goldMark'>We'll Handle Everything</mark>",
    subtitle: "You can still approve important decisions - but you won't be distracted by your property. Our expert team will take care of all the grunt work.",
    colors: [
        {category: 'finances', color: 'rgb(77, 186, 189)'},
        {category: 'amenities', color: 'rgb(77, 186, 189)'},
    ],
    benefits: [
        {id: 1, title: 'Tax Filings', text: 'All property related tax returns will be filed everywhere they need to be.', icon: 'meh-icon', category: 'finances'},
        {id: 2, title: 'Bill Payments', text: "We'll handle paying bills for vendors, utilities and common fees for the all properties you own", icon: 'dollar-sign-icon', category: 'finances'},
        {id: 3, title: 'Leasing', text: "Earn income with passive short term leasing whenever you want, fully managed by us", icon: 'user-icon', category: 'finances'},
        {id: 4, title: 'Renovation & Repairs', text: "Professionally managed renovations that are fast, without delays, overcharging or screw-ups -- guaranteed by us.", icon: 'home-icon', category: 'finances'},
        {id: 5, title: 'Owner Privileges', text: "Rent other AirHomes whenever you want at special owners rates that are 30-50% discounted.", icon: 'star-icon', category: 'amenities'},
        {id: 6, title: 'Cleaning Services', text: "Book cleaning and laundry service whenever you want. You don't have to be around to let our fully insured staff in.", icon: 'trash-icon', category: 'amenities'},
    ]
}