import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'landingpage',
    component: () => import(/* webpackChunkName: "landingpage" */ '../views/LandingPage.vue')
  },
  {
    path: '/firststep',
    name: 'iwantout',
    component: () => import(/* webpackChunkName: "landingpage" */ '../views/IWantOut.vue')
  },
  {
    path: '/articles',
    name: 'articles',
    component: () => import(/* webpackChunkName: "landingpage" */ '../views/ArticlesHome.vue')
  },
  {
    path: '/articlepage/:articleID',
    name: 'articlepage',
    component: () => import(/* webpackChunkName: "landingpage" */ '../views/ArticlePage.vue')
  },
  {
    path: '/marketoverview',
    name: 'marketoverview',
    component: () => import(/* webpackChunkName: "landingpage" */ '../views/MarketOverview.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
