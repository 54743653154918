import { content as landing } from './en/landing/main.js'
import { content as iwantout } from './en/iwantout/main.js'

const content = {
    en: { landing: landing, iwantout: iwantout }
}

export function getContent(language){
    // returns the content for a specific language
    if (Object.keys(content).includes(language) === false){
        throw `Lanugage ${language} is not supported. Supported language keys are ${Object.keys(content)}`;
    }
    return content[language]
}