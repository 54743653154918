import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dynamicColors: {
      navBarColor: 'transparent',
      appBackgroundColor: 'white',
      darkLogo: true
    },
    language: {
      userPreferredLanguage: 'en',
    }
  },
  getters: {
  },
  mutations: {
    setLogoIsDark: function(state, dark){
      // causes logo to be dark or light variant
      state.dynamicColors.darkLogo = dark
    }
  },
  actions: {
  },
  modules: {
  }
})
