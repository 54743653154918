<template>
  <v-app>
    <top-app-bar/>
    <div @click="$router.push({name:'landingpage'})" style="z-index: 999; top: -120; cursor: pointer; height: 100px; width: 120px; position: absolute;">
      <air-home-logo :dark="$store.state.dynamicColors.darkLogo" :compact="true" :hide="lastScrollPosition > 30"/>
    </div>
      <v-main>
        <vue-splash
          :show="true"
          title="AIRHOME"
          background-color="#0E0420"
          :logo="require('@/assets/logo/dark-square-transparent.svg')"
          :size="120"
          color="rgba(255, 255, 255, 0.0)"
          :fixed="true"
        />
        <router-view/>
      </v-main>
    <page-footer :content="content.footer" ></page-footer>
  </v-app>
</template>

<script>
import AirHomeLogo from './components/global/airhomelogo/AirHomeLogo.vue';
import TopAppBar from './components/global/topappbar/TopAppBar.vue';
import { getContent } from '@/content/main.js'
import PageFooter from './components/global/pagefooter/PageFooter.vue';

export default {
  name: 'App',
  components: { TopAppBar, AirHomeLogo, PageFooter },
  data: () => ({
    lastScrollPosition: 0,
  }),
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll () {
        // Get the current scroll position
        const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
        // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
        if (currentScrollPosition < 0) {
          return
        }
        // Here we determine whether we need to show or hide the navbar
        this.showNavbar = currentScrollPosition < this.lastScrollPosition
        // Set the current scroll position as the last scroll position
        this.lastScrollPosition = currentScrollPosition
      }
  },
  computed: {
      content: function(){
        return getContent(this.$store.state.language.userPreferredLanguage).landing
      }
  },
}
</script>

<style lang="scss">
// shadows for buttons or cards
.darkShadow{
  box-shadow: rgba(14, 4, 32, 0.2) 0px 8px 24px;
}
.primaryShadow{
  box-shadow: rgba(33, 9, 78, 0.2) 0px 8px 24px;
}
.secondaryShadow{
  box-shadow: rgba(76, 161, 163, 0.2) 0px 8px 24px;
}
.goldShadow{
  box-shadow: rgba(250, 161, 57, 0.2) 0px 8px 24px;
}
// buttons
.primaryGradButton{
    background-image: linear-gradient(to right, #21094E 0%, #0E0420  51%, #21094E  100%);
    transition: 0.5s;
    box-shadow: 0px 8px 15px rgba(33, 9, 78, 0.3);
    background-size: 200% auto;
    color: white;            
    display: block;
}
.primaryGradButton:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}
.secondaryGradButton{
    background-image: linear-gradient(to right, #02AAB0 0%, #00CDAC  51%, #02AAB0  100%);
    transition: 0.5s;
    box-shadow: 0px 8px 15px rgba(3, 143, 120, 0.3);
    background-size: 200% auto;
    color: white;            
    display: block;
}
.secondaryGradButton:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}
.darkGradButton{
    background-image: linear-gradient(to right, #0E0420 0%, #040109  51%, #0E0420  100%);
    transition: 0.5s;
    box-shadow: 0px 8px 15px rgba(255, 255, 255, 0.015);
    background-size: 200% auto;
    color: white;            
    display: block;
}
.darkGradButton:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}
// mark for highlighting text
.goldMark{
  color: #FAA139;
  background-color: transparent;
}
.gradientMark {
  font-size: 7.5vh;
  background: linear-gradient(to right, #4806c1, #FAA139);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  text-shadow: 4px 4px 10px rgb(7, 1, 18, 0.5);
}
.primaryMark {
  color: #21094E;
  background-color: transparent;
}
.darkGradientMark {
  font-size: 7.5vh;
  background: linear-gradient(to right, #0E0420, #FAA139);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  text-shadow: 4px 4px 10px rgb(7, 1, 18, 0.5);
}
// mobile text
@media (max-width: 600px) {
  .heroTitle{
    font-weight: 700;
    font-size: 42px;
    white-space: pre-wrap;
    text-shadow: 4px 4px 10px rgb(7, 1, 18, 0.4);
    text-align: center;
  }
  .subtitle{
    font-weight: 500;
    font-size: 18px;
    white-space: pre-wrap;
    letter-spacing: 0.1em;
    text-shadow: 4px 4px 10px rgb(7, 1, 18, 0.3);
    text-align: center;
  }
  .writing{
    font-size: 16px;
    font-weight: 500;
  }
}
// desktop text
@media (min-width: 600px) {
  .heroTitle{
    font-weight: 700;
    font-size: 4.5vw;
    line-height: 5.5vw;
    white-space: pre-wrap;
    text-shadow: 4px 4px 10px rgb(7, 1, 18, 0.4);
    text-align: center;
  }
  .subtitle{
    font-weight: 500;
    font-size: 18px;
    white-space: pre-wrap;
    line-height: 2vw;
    letter-spacing: 0.1em;
    text-shadow: 4px 4px 10px rgb(7, 1, 18, 0.3);
    text-align: center;
  }
  .writing{
    font-size: 16px;
    font-weight: 500;
  }
}
.buttonText{
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
}
.centeredText{
  text-align: center;
}
// layout helpers
.fullWidthRow{
  width: 100%
}
// generic text e.g. p
.copyText{
  font-weight: 500;
  white-space: pre-wrap;
  text-shadow: 4px 4px 10px rgb(7, 1, 18, 0.3);
}
.preTitle{
  font-weight: 500;
  text-transform: uppercase;
  text-shadow: 4px 4px 10px rgb(7, 1, 18, 0.3);
  letter-spacing: 0.1em;
}
// transition
.animatedTransition{
  transition: all 0.5s;
}
</style>