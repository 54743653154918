export const content = {
    title: "Dream Homes,\n<mark class='goldMark'>For A Dream Lifestyle</mark>",
    subtitle: "The best way to decide if an AIRHOME is right for you is to try one out. Fill in the form to learn more and request a free stay once our first properties go live in Q1 2023.",
    quiz: [
        {title: 'Tell us about you', id: 4, children: [
        {field: 'ALREADY_LIVING_OVERSEAS', type: 'select', label: 'Are you already living overseas?', items: [{text: 'Yes', value: true}, {text: 'No', value: false}]},
        {field: 'LOCATION_FLEXIBLE_INCOME', type: 'select', label: 'Do you have location flexible income?', items: [{text: 'Yes', value: true}, {text: 'No', value: false}]}
        ]},
        {title: 'Tell us about your ideal property', id: 1, children: [
            {field: 'CITIES', type: 'text', label: 'Which cities would you like to live in?', placeholder: 'London, Paris, Rome', required: true, items: null},
            {field: 'TARGET_INVESTMENT_AMOUNT', type: 'select', label: 'Do you have a budget in mind?', items: [{text: '$150,000', value: '150'}, {text: '$250,000', value: '250'}, {text: '$300,000', value: '300'}, {text: '$500,000', value: '500'}, {text: '$750,000', value: '750'}, {text: '$1,000,000', value: '1000'}], required: true},
        ]},
        {title: 'Get your results', id: 3, children: [
            {field: 'EMAIL', type: 'text', label: "Email", required: true, items: null},
            {field: 'PHONE_NUMBER_INCL_DIALLING_CODE', type: 'text', label: "Phone Number (incl. country code e.g. +1)", required: true, items: null}
        ]},
    ],
    video: 'https://airhome-storage.ams3.digitaloceanspaces.com/airhome-landing/signup.mp4',
    reasonsToSignUp: [
        {icon: 'check-icon', text: 'Join an exclusive community of elite travellers'},
        {icon: 'check-icon', text: 'Cleaning and laundry service'},
        {icon: 'check-icon', text: 'Cloud storage for your stuff and gym access'},
        {icon: 'check-icon', text: 'Seasoned investment staff from top investment funds'},
        {icon: 'check-icon', text: 'Own property in growing cities'}
    ]
}