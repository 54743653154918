<template>
    <v-img class="logo-position" style="transition: all 0.5s ease;" :style="{'transform' : hide ? 'translateY(-30vh)' : 'translateY(0px)'}" :max-width="compact ? 120 : 400" :src="getLogoFile(logoImage)"/>
</template>

<script>
export default {
    name: 'AirHomeLogo',
    props: {
        hide: {
            type: Boolean,
            default: false,
        },
        dark: {
            // will display the dark version of the logo (i.e. for dark background with white text)
            type: Boolean,
            default: true,
        },
        compact: {
            // will display a horizontally compact version of the logo
            type: Boolean,
            default: false, 
        },
        transparentBackground: {
            // the logo will have a transparent background vs. colored
            type: Boolean,
            default: true
        }
    },
    methods: {
        getLogoFile: function(logo){
            return require('@/assets/logo/' + logo)
        }
    },
    computed: {
        logoImage: function(){
            // returns the logo to be displayed
            if (this.dark){
                return 'dark-square-transparent.svg'
            }
            if (!this.dark){
                return 'light-square-transparent.svg'
            }
            // this path is never triggered
            return null
        }
    }
}
</script>

<style lang="scss">
.logo-position{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
}
</style>