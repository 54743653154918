<template>
    <v-btn @click="signUp" rounded x-large class="darkGradButton text-none"><h2 class="buttonText">{{ buttonText }}</h2></v-btn>
</template>

<script>
export default {
    name: 'SignUpButton',
    props: {
        buttonText: {
            type: String,
            default: 'Learn More'
        }
    },
    methods: {
        signUp: function(){
            // scroll to the signup form
            if (this.$route.name === 'landingpage'){
                this.$scrollTo('#signupform')
            } else {
                setTimeout(() => this.$router.push({name: 'landingpage'}), 100);
                setTimeout(() => this.$scrollTo('#signupform'), 300);
                }
            return
            }
        }
}
</script>