export const content = {
    title: "Try Out\n<mark class='goldMark'>Overseas Property</mark>",
    subtitle: "Owning overseas real estate the wrong way can be a pain. But done right it's a fantastic investment and also unlocks a better lifestyle. Why not start by staying for free in one of our properties and see if you like what you see? Fill in the form to learn more and request a free stay once our first properties go live in Q1 2023.",
    quiz: [
        {title: 'Tell us about you', id: 4, children: [
        {field: 'ALREADY_LIVING_OVERSEAS', type: 'select', label: 'Have you thought about buying overseas property before?', items: [{text: 'Yes', value: true}, {text: 'No', value: false}]},
        {field: 'LOCATION_FLEXIBLE_INCOME', type: 'select', label: 'Do you own overseas propert already?', items: [{text: 'Yes', value: true}, {text: 'No', value: false}]}
        ]},
        {title: 'Tell us about your ideal property', id: 1, children: [
            {field: 'CITIES', type: 'text', label: 'Do you know any cities where you would you like to own?', placeholder: 'London, Paris, Rome', required: true, items: null},
            {field: 'TARGET_INVESTMENT_AMOUNT', type: 'select', label: 'How much do you want to invest?', items: [{text: '$150,000', value: '150'}, {text: '$250,000', value: '250'}, {text: '$300,000', value: '300'}, {text: '$500,000', value: '500'}, {text: '$750,000', value: '750'}, {text: '$1,000,000', value: '1000'}], required: true},
        ]},
        {title: 'Get your results', id: 3, children: [
            {field: 'EMAIL', type: 'text', label: "Email", required: true, items: null},
            {field: 'PHONE_NUMBER_INCL_DIALLING_CODE', type: 'text', label: "Phone Number (incl. country code e.g. +1)", required: true, items: null}
        ]},
    ],
    video: 'https://airhome-storage.ams3.digitaloceanspaces.com/airhome-landing/signup.mp4',
    reasonsToSignUp: [
        {icon: 'check-icon', text: 'Join an exclusive community of elite travellers'},
        {icon: 'check-icon', text: 'Cleaning and laundry service'},
        {icon: 'check-icon', text: 'Cloud storage for your stuff and gym access'},
        {icon: 'check-icon', text: 'Seasoned investment staff from top investment funds'},
        {icon: 'check-icon', text: 'Own property in growing cities'}
    ]
}