export const content = {
    title: 'Honest & Transparent Pricing',
    subtitle: 'You\'ll pay a fixed % of the price when you actually buy your AIRHOME. If you don\'t buy, you won\'t pay anything.',
    itemTitle: 'Your Partner Forever',
    itemText: 'You\'ll get property management from us as long as you own your AIRHOME. When you sell, we will continue to manage the property for the new owner.',
    whatsIncluded: 'What\'s included',
    includedItems: ['Property search', 'Transaction management', 'Renovation management', 'Property management'],
    pricePreTitle: 'Pay once, enjoy forever',
    price: '3.5%',
    belowPrice: 'Of property purchase price',
    buttonCTA: 'Learn More',    
}