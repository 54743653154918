import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import checkView from 'vue-check-view'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMobileDetection from "vue-mobile-detection";
import VueMixpanel from 'vue-mixpanel'

import VueSplash from 'vue-splash';
 
Vue.use(VueSplash);

Vue.use(VueMobileDetection) // detects if screen size is mobile or dekstop

Vue.use(VueAxios, axios)

Vue.use(checkView) // library for seeing if an element is in the viewport

require('vue2-animate/dist/vue2-animate.min.css') // animated transitions

var VueScrollTo = require('vue-scrollto');

Vue.use(VueMixpanel, {
  token: 'f6c607176ef7bef86c73261d86a81ff8'
})
 
Vue.use(VueScrollTo)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
